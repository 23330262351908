import { useUser } from "@auth0/nextjs-auth0";
import Link from "next/link";
import { useEffect, useId, useRef, useState } from "react";
import { gravatar } from "../lib/gravatar";
import styles from "./profile.module.css";

export default function Profile() {
  const { user } = useUser();
  const [isOpen, setIsOpen] = useState(false);
  const menuId = useId();

  // Close the menu when clicking anywhere else on the page
  useEffect(() => {
    function handler() {
      setIsOpen(false);
    }
    addEventListener("click", handler);
    return () => {
      removeEventListener("click", handler);
    };
  }, [isOpen]);

  return (
    <button
      aria-expanded={isOpen}
      aria-haspopup="menu"
      aria-controls={menuId}
      className={styles.outer}
      onClick={(event) => {
        // Prevent the page's click listener from seeing the button click event,
        // or else the menu will open and then instantly close again...
        event.stopPropagation();
        setIsOpen(!isOpen);
      }}
      style={{
        "--avatar-image": user ? `url('${gravatar(user.email)}')` : undefined,
      }}
    >
      <div
        className={styles.profileWrapper}
        // Despite not being documented, this works if the Auth0 OIDC flow has
        // been configured to have these fields...
      >
        {user?.given_name} {user?.family_name}
        <span className={styles.avatar} />
      </div>
      <menu hidden={!isOpen} className={styles.menu} id={menuId}>
        <li className={styles.menuItem}>
          <Link href="/account">My Account</Link>
        </li>
        <li className={styles.menuItem}>
          <Link href="/zaps">My Zaps</Link>
        </li>
        <li className={styles.menuItem}>
          <a href="/api/auth/logout">Log Out</a>
        </li>
      </menu>
    </button>
  );
}
