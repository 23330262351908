import { CSSProperties } from "react";

export function cssCustomProperties(
  properties: Record<string, string>
): CSSProperties {
  const obj: Record<string, string> = {};
  for (const key in properties) {
    obj[`--${key}`] = properties[key];
  }
  return obj;
}
