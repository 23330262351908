import { UserProvider } from "@auth0/nextjs-auth0";
import Head from "next/head";
import Header from "../components/header";
import { ThemeProvider, createTheme } from "@mui/material";
import "./global.css";
import { AppProps } from "next/app";
import { SplitFactoryProvider } from "@splitsoftware/splitio-react";
import { splitConfig } from "../splitio-config";

// TODO: Customize theme
// https://mui.com/material-ui/customization/theming/
const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#3d4592",
    },
  },
  typography: {
    fontFamily: '"Open Sans", sans-serif',
    button: {
      textTransform: "none",
    },
  },
  shape: {
    borderRadius: 3,
  },
  components: {
    MuiStack: {
      defaultProps: {
        useFlexGap: true,
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          color: "var(--color-text)",
        },
      },
    },
    MuiListItemButton: {
      defaultProps: {
        divider: true,
      },
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            // Override styles for mui ListItemButton component
            backgroundColor: "var(--ui-semantic-action-bg-selected)",
            outline: "1px solid var(--ui-semantic-action-bg-primary)",
            outlineOffset: "-1px",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          color: "inherit",
          "&.Mui-disabled": {
            cursor: "not-allowed",
          },
        },
      },
    },
  },
});

export default function App({ Component, pageProps }: AppProps) {
  return (
    <SplitFactoryProvider config={splitConfig}>
      <ThemeProvider theme={theme}>
        <UserProvider>
          <div className="grid-container">
            <Head>
              <title>Zapier Space</title>
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1"
              />
            </Head>
            <Header />
            <div className="body">
              <Component {...pageProps} />
            </div>
          </div>
        </UserProvider>
      </ThemeProvider>
    </SplitFactoryProvider>
  );
}
