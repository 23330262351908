import { ISplitFactoryProps } from "@splitsoftware/splitio-react";

if (!process.env.NEXT_PUBLIC_SPLIT_SDK_CLIENT_API_KEY) {
  throw new Error(
    "NEXT_PUBLIC_SPLIT_SDK_CLIENT_API_KEY must be set in the environment"
  );
}

export const splitConfig: ISplitFactoryProps["config"] = {
  core: {
    authorizationKey: process.env.NEXT_PUBLIC_SPLIT_SDK_CLIENT_API_KEY,
    key: "anonymous",
  },
};

export const workflowStepTestingFlag = "tps-zapier-space-workflow-step-testing";
// Other treatments as you add them
