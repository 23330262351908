import { useUser } from "@auth0/nextjs-auth0";
import Link from "next/link";
import dynamic from "next/dynamic";
import { Button } from "./button";
import styles from "./header.module.css";
import Profile from "./profile";

const NavOptions = dynamic(() => import("./navOptions"), { ssr: false });

export default function Header() {
  const { user } = useUser();
  return (
    <div className={styles.nav}>
      <Link className={styles.logo} href="/about">
        <img alt="Space by Zapier" src="/logotype.svg" />
      </Link>
      <NavOptions />
      <div className={styles.profile}>
        {user ? (
          <Profile />
        ) : (
          <a href="/api/auth/login" className="linkButton">
            <Button presentationOnly>Sign In</Button>
          </a>
        )}
      </div>
    </div>
  );
}
