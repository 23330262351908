import { clsx } from "clsx";
import { cssCustomProperties } from "../lib/cssCustomProperties";
import styles from "./button.module.css";

export type ButtonProps = JSX.IntrinsicElements["button"] & {
  presentationOnly?: boolean;
  variant?: "filled" | "outline" | "icon";
  size?: number;
};

export function Button({
  presentationOnly = false,
  variant = "filled",
  className,
  style,
  size = 24,
  ...props
}: ButtonProps) {
  const Component = presentationOnly ? "span" : "button";
  return (
    <Component
      data-variant={variant}
      className={clsx(styles.button, className)}
      tabIndex={presentationOnly ? -1 : undefined}
      style={{
        ...cssCustomProperties({ size: `${size}px` }),
        ...style,
      }}
      {...props}
    />
  );
}
